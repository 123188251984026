import "./inputStyle.scss";
import { FormConig } from "../../interfaces";
import { HMO } from "../../constants/hmo";
import {
  FormControl,
  Select,
  MenuItem,
  SelectChangeEvent,
} from "@mui/material";
import { useState } from "react";

interface DropdownProps {
  inputAttribute: FormConig;
  onChange: (e: any) => void | undefined;
}

export const Dropdown = ({ inputAttribute, onChange }: DropdownProps) => {
  const [selectedValue, setSelectedValue] = useState("");

  const handleChange = (event: SelectChangeEvent) => {
    setSelectedValue(event.target.value);
    onChange(event.target.value);
  };

  const options = [
    { value: HMO.leumit, label: HMO.leumit },
    { value: HMO.clalit, label: HMO.clalit },
    { value: HMO.maccabi, label: HMO.maccabi },
    { value: HMO.meuhedet, label: HMO.meuhedet },
  ];

  return (
    <div className="form-element">
      <label htmlFor={inputAttribute.name}>
        {" "}
        {inputAttribute.label}
        <span className="must-text"> חובה</span>
      </label>

      <FormControl className="select">
        <Select
          value={selectedValue}
          onChange={handleChange}
          displayEmpty
          variant="standard"
          disableUnderline
          inputProps={{ "aria-label": "Without label" }}
          labelId="label"
          id="select"
          name={inputAttribute.name}
          required
          MenuProps={{
            sx: {
              "&& .Mui-selected": {
                backgroundColor: "#ecf1ee",
              },
            },
          }}
        >
          <MenuItem value="" disabled>
            -- בחר קופת חולים --
          </MenuItem>
          {options.map((option) => {
            return (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
      {inputAttribute.errorMsg && (
        <label htmlFor={inputAttribute.name}>
          <span className="invalid-feedback"> {inputAttribute.errorMsg}</span>
        </label>
      )}
    </div>
  );
};

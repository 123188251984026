import { GoogleAnalytics } from "../google-analytics";
import { FormConig } from "../interfaces";

  const onlyNumbers = (str: string)=> {
    return /^[0-9]+$/.test(str);
  }

export const checkValidation = (input: FormConig, value: any) => {
  if(value === undefined){
    return `שדה ${input.label} לא תקין`;
  }
  switch (input.type) {
    case "number":
      if (input.name === "id") {
        if (value.length < 9 || value.length > 9 || !onlyNumbers(value)){
          return "מספר תעודת זהות לא תקין";
      }
    }
      break;
    case "email":
      const emailReg = /^([a-zA-Z0-9_\-.]+)@([a-zA-Z0-9_\-.]+).([a-zA-Z]{2,5})$/;
      let email = JSON.stringify(value)
      const user = email.substring(0, email.indexOf('@'));
      const domain = email.substring(email.lastIndexOf("@") +1);
      if (!emailReg.test(value) || user.length>64 || domain.length > 254) {
        return "כתובת מייל לא תקינה";
      }
      break;
    case "tel":
      const phoneReg = /05[0-9]{8}$/;
      if (!phoneReg.test(value)) {
        return "מספר טלפון לא תקין";
      }
      break;
    case "text":
      if (value.length < 2) {
        if(input.name==='address'){
          return ` כתובת לא תקינה`;
        }
        return ` ${input.label} לא תקין`;
      }
      break;
    case "textarea":
      if (value.length < 2) {
        return ` ${input.label} לא תקין`;      }
      break;
    case "dropdown":
      if (value === "") {
        return ` ${input.label} לא תקין`;
      }
      break;
    default:
      return "";
  }
};

export const sendEventGoogleAnalytics= (Category: string, Action: string, Label: string)=>{
  GoogleAnalytics({
    eventName: 'auto_event',
    categoryName: Category,
    actionName: Action,
    labelName:Label
  });
}

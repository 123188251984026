import { FormConig, PageData } from "../interfaces/index";
import doctor from "../assets/images/doctor.svg";

export const SendPrescriptionInputsFields: FormConig[] = [
  {
    id:1,
    name: "firstname",
    label: "שם פרטי",
    type: "text",
    domType: "input",
    errorMsg: '',
    maxLength:20,
    minLength:2,
    insertValue: false
  },
  {
    id:2,
    name: "lastname",
    label: "שם משפחה",
    type: "text",
    domType: "input",
    errorMsg: '',
    maxLength:20,
    minLength:2,
    insertValue: false
  },
  {
    id:3,
    name: "phone",
    label: "טלפון אישי",
    type: "tel",
    domType: "input",
    errorMsg: '',
    insertValue: false
  },
  {
    id:4,
    name: "hmo",
    label: "באיזו קופת חולים את/ה?",
    type: "dropdown",
    domType: "dropdown",
    errorMsg: '',
    insertValue: false
  },
  {
    id: 5,
    name: "statment",
    label: "אני מצהיר/ה כי הפרטים שמילאתי נכונים",
    type: "checkbox",
    domType: "input",
    errorMsg: "",
    insertValue: false,
  },
];

export const SendPrescriptionPage: PageData = {
  title: "בקשה לניפוק קנביס רפואי",
  image: doctor,
  header:
  "אנא הזינו פרטים ונחזור אליכם בהקדם.",
  emailTitle: 'בקשה לניפוק קנביס רפואי',
  formName: 'TevaCann - request form'
};

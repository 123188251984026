import "./textAreaStyle.scss";
import { FormConig } from "../../interfaces";

interface TextAreaProps {
  inputAttribute: FormConig;
  onChange: (e: any) => void | undefined;
}

export const TextArea = ({ inputAttribute, onChange }: TextAreaProps) => {
  return (
    <div className="form-element">
      <label htmlFor={inputAttribute.name}>
        {" "}
        {inputAttribute.label}
        <span className="must-text"> חובה</span>
      </label>

      <textarea
        className="form-input"
        name={inputAttribute.name}
        maxLength={500}
        style={{height:'100px'}}
        required
        onChange={e=> onChange(e.target.value)}
      />
      {inputAttribute.errorMsg && (
        <label htmlFor={inputAttribute.name}>
          <span className="invalid-feedback"> {inputAttribute.errorMsg}</span>
        </label>
      )}
    </div>
  );
};

import { ChangeEvent } from "react";
import { Dispatch, SetStateAction } from "react";
import { FormConig } from "../../interfaces";
import { sendEventGoogleAnalytics } from "../../utils";
import "./consitions.scss";

type ConditionProps = {
  setConfirmConditions: Dispatch<SetStateAction<boolean>>;
  FormName: string;
  contactNurseConfirm?: FormConig;
};

export const Condition = ({ setConfirmConditions,FormName, contactNurseConfirm }: ConditionProps) => {
  const handelCheckbox = (e: ChangeEvent<HTMLInputElement>) => {
    sendEventGoogleAnalytics(FormName, 'checkbox checked','אני מאשר' )
    setConfirmConditions(e.target.checked);
  };

  return (
    <div className="column">
      <div>
        <p>
          קראתי והבנתי:
          <span aria-hidden="true" className="must-text">
            חובה
          </span>
        </p>
      </div>

      <div>
      {contactNurseConfirm && (
        <div>
          <input
            className="disabled-checkbox"
            name="הרשאה ליצירת קשר"
            type="checkbox"
            value={contactNurseConfirm.label}
            checked
            disabled
          />
          <label className="disabled-checkbox-label" htmlFor="">{contactNurseConfirm.label}</label>
        </div>
        )}
        <input
          name="הרשאה"
          type="checkbox"
          value="אני מאשר/ת את הפרטים מטה"
          required
          onChange={(e) => handelCheckbox(e)}
        />
        <label htmlFor="הרשאה ליצירת קשר">אני מאשר/ת את הפרטים מטה</label>
      </div>

      <div>
        <p>
          המידע שתמסור/י ישמש את טבע ישראל בע"מ וחברות הקשורות אליה או פועלות
          מטעמה, למטרת תפעול השירות המבוקש, טיפול בפנייתך, יצירת קשר איתך, מחקר
          וססטטיסטיקה ומילוי חובות שדרושות מכוח דין (כולל למשל דיווח על תופעות
          לוואי). ככלל, אין חובה חוקית למסור את המידע אולם במקרים מסויימים (כמו
          רכישת מוצרים) מסירת מידע מסויים נדרש על פי דין. אם תמסור/י מידע על
          אחרים באחריותך לוודא שהם מסכימים לכך ולאמור{" "}
          <a
            href="https://www.teva.co.il/globalassets/teva-il-scs-vision/teva-il-scs-vision-files/about-teva/privacy_and_cookies_notice_hebrew.pdf"
            target="_blank"
            rel="noreferrer"
          >
            במדיניות הפרטיות
          </a>
          . טבע הינה חברה גלובלית, ולכן תפעול השירות עשוי לכלול העברה של מידע אל
          מחוץ לישראל. למידע נוסף בדבר עיבוד המידע אודותייך, לרבות זכויותייך
          לגביו, אנא ראה/י את{" "}
          <a
            href="https://www.teva.co.il/globalassets/teva-il-scs-vision/teva-il-scs-vision-files/about-teva/privacy_and_cookies_notice_hebrew.pdf"
            target="_blank"
            rel="noreferrer"
          >
            מדיניות הפרטיות
          </a>{" "}
          שלנו.
        </p>
      </div>
    </div>
  );
};

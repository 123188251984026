import "./inputStyle.scss";
import { FormConig } from "../../interfaces";

interface InputProps {
  inputAttribute: FormConig;
  onChange: (e: any) => void | undefined;
}

export const Input = ({ inputAttribute, onChange }: InputProps) => {
  return (
    <div
      className={
        inputAttribute.type === "checkbox"
          ? "checkbox-container"
          : "form-element"
      }
    >
      <label htmlFor={inputAttribute.name}>
        {" "}
        {inputAttribute.label}
        <span className="must-text"> חובה</span>
      </label>

      <input
        className="form-input"
        name={inputAttribute.name}
        type={inputAttribute.type}
        onChange={(e) => onChange(e.target.value)}
        maxLength={inputAttribute.maxLength? inputAttribute.maxLength :undefined }
        minLength={inputAttribute.minLength? inputAttribute.minLength :undefined }
        required
      />
      {inputAttribute.errorMsg && (
        <label htmlFor={inputAttribute.name}>
          <span className="invalid-feedback"> {inputAttribute.errorMsg}</span>
        </label>
      )}
    </div>
  );
};

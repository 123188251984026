declare global {
  interface Window {
    dataLayer: any[];
  }
}

type GoogleAnalyticsProps = {
  eventName: string;
  categoryName: string;
  actionName: string;
  labelName: string
};

export const GoogleAnalytics = ({
  eventName,
  categoryName,
  actionName,
  labelName
}: GoogleAnalyticsProps) => {
  window.dataLayer.push({
    event: eventName,
    Category: categoryName,
    Action : actionName,
    Label:labelName
  });
};

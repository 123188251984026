import { useState } from "react";
import { FormConig, PageData } from "../interfaces/index";
import { ContactUsPage, ContactUsFields} from "../constants/index";
import { Header } from "../components/header/header";
import { Form } from "../components";
import { checkValidation, sendEventGoogleAnalytics } from "../utils";

export const ContactUsForm = () => {
  const [inputAttributes, setFormConig] =
    useState<FormConig[]>(ContactUsFields);
  const pageData: PageData = ContactUsPage;

  const onFormChange = (value: any, attribiute: FormConig) => {
    const updatedAttribute = inputAttributes.map((input) => {
      if (input.id === attribiute.id) {
        const isValidText = checkValidation(input, value);
        if (isValidText) {
          input.errorMsg = isValidText;
        } else {
          input.errorMsg = "";
        }
        if(value.length === 0){
          input.insertValue= false
        }
        if(!attribiute.insertValue && value.length >=1){
          input.insertValue= true
          sendEventGoogleAnalytics(pageData.formName, 'field fill', input.label)  
        }
      }
      return input;
    });
    setFormConig(updatedAttribute);
  };

  return (
    <>
      <Header img={pageData.image} headerLabel={pageData.title} />
      <Form
        formHeaderText={pageData.header}
        subHeader={pageData.subHeader}
        emailTitle={pageData.emailTitle}
        inputAttributes={inputAttributes}
        onFormChange={(e, attribiute) => onFormChange(e, attribiute)}
        FormName = {pageData.formName}
      />
    </>
  );
};

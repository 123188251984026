import { FormConig, PageData } from "../interfaces/index";
import consultation from "../assets/images/consultation.svg";

export const ConsultationFields: FormConig[] = [
  {
    id: 1,
    name: "firstname",
    label: "שם פרטי",
    type: "text",
    domType: "input",
    errorMsg: '',
    maxLength:20,
    minLength:2,
    insertValue: false
  },
  {
    id: 2,
    name: "lastname",
    label: "שם משפחה",
    type: "text",
    domType: "input",
    errorMsg: '',
    maxLength:20,
    minLength:2,
    insertValue: false
  },
  {
    id: 3,
    name: "phone",
    label: "טלפון אישי",
    type: "tel",
    domType: "input",
    errorMsg: '',
    insertValue: false
  },
  {
    id: 4,
    name: "email",
    label: "כתובת אימייל",
    type: "email",
    domType: "input",
    errorMsg: '',
    maxLength:256,
    insertValue: false
  },
  {
    id: 5,
    name: "contactNurseConfirm",
    label: "אני מעוניין/ת לדבר עם אחות מוסמכת",
    type: "checkbox",
    domType: "checkbox",
    errorMsg: '',
    insertValue: false
  },
];

export const ConsultationPage: PageData = {
  title: "ייעוץ עם אחות מוסמכת",
  image: consultation,
  header: "נא מלא/י את הטופס ואחות מוסמכת תחזור אליך.",
  subHeader:"ניתן ליצור עימנו קשר גם בטלפון: 1-800-200-266.",
  emailTitle: 'בקשה לייעוץ עם אחות מוסמכת',
  emailText: 'אני מעוניין לדבר עם אחות מוסמכת',
  formName :'TevaCann - nurse counselling form'
};

import ReCAPTCHA from "react-google-recaptcha";
import "./captchaStyle.scss";
import { Dispatch, SetStateAction } from "react";
import { sendEventGoogleAnalytics } from "../../utils";


type CaptchaProps = {
  setConfirmCaptcha: Dispatch<SetStateAction<boolean>>;
  FormName: string;
};

export const Captcha = ({ setConfirmCaptcha, FormName }: CaptchaProps) => {
  const handelConfirmCaptcha = (e: string | null) => {
    if (e) {
      sendEventGoogleAnalytics(FormName, 'checkbox checked','אני לא רובוט' )
      setConfirmCaptcha(true);
    }
  };

  const recaptchaExpired = () => {
    setConfirmCaptcha(false);
  };

  return (
    <div className="captcha">
      <ReCAPTCHA
        sitekey="6Lc0alEhAAAAAAooiwnBK_KLoMnfIU7dS_wWCwbO"
        onChange={(e) => handelConfirmCaptcha(e)}
        size="compact"
        onExpired={recaptchaExpired}
      />
    </div>
  );
};

import "../footer/footer.scss";
import { allLinks, FollowUs } from "../../constants/footer";

export const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <div className="footer">
        <div className="footer-container">
          <div className="links">
          {allLinks.map((linksSection, index) => {
            return (
              <div key={index} className="links-container">
                <ul className="link-list">
                  {linksSection.map((link, index) => {
                    return (
                      <li key={index}>
                        <a
                          href={link.href}
                          target={link.target}
                          aria-label={link.text}
                        >
                          {link.text}
                        </a>
                      </li>
                    );
                  })}
                </ul>
              </div>
            );
          })}

          <div className="links-container">
            <p className="follow-us">Follow Us</p>
            <div className="share-buttons">
              <ul className="share-buttons-list">
                {FollowUs.map((button, index) => {
                  return (
                    <li key={index} className="share-buttons-item">
                      <a
                        href={button.href}
                        target="_blank"
                        rel="noreferrer"
                        className="share-buttons-link"
                        title="Follow us"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 34 34"
                          sg-icons-dir="sg/components/icon/icons"
                          role="img"
                          className="vi-icon"
                          aria-label={button.title}
                        >
                          <path
                            fillRule="evenodd"
                            d={button.path}
                            transform="translate(2 2)"
                          ></path>
                        </svg>
                        <span className="sr-only">{button.title}</span>
                      </a>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
          </div>
        
          <div className="copyright">
          <p>Copyright © {currentYear} TevaCann</p>
        </div>
        </div>

    </div>
  );
};

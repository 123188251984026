import { FormConig, PageData } from "../interfaces/index";
import contactUs from "../assets/images/contactUs.svg";

export const ContactUsFields: FormConig[] = [
  {
    id:1,
    name: "firstname",
    label: "שם פרטי",
    type: "text",
    domType: "input",
    errorMsg: '',
    maxLength:20,
    minLength:2,
    insertValue: false
  },
  {
    id:2,
    name: "lastname",
    label: "שם משפחה",
    type: "text",
    domType: "input",
    errorMsg: '',
    maxLength:20,
    minLength:2,
    insertValue: false
  },
  {
    id:3,
    name: "phone",
    label: "טלפון אישי",
    type: "tel",
    domType: "input",
    errorMsg: '',
    insertValue: false
  },
  {
    id:4,
    name: "email",
    label: "כתובת אימייל",
    type: "email",
    domType: "input",
    errorMsg: '',
    maxLength:256,
    insertValue: false
  },
  {
    id:5,
    name: "description",
    label: "תיאור הפנייה",
    type: "textarea" ,
    domType: "textarea",
    errorMsg: '',
    insertValue: false
  }
];

export const ContactUsPage: PageData = {
  title: "TevaCann כאן בשבילכם",
  image: contactUs,
  header: "מטופלים יקרים, מלאו את הטופס ונציג מטעמנו יחזור אליכם." ,
  subHeader:  " ניתן ליצור עימנו קשר גם  בטלפון: 1-800-805-005.",
  emailTitle: ' פנייה לגבי Tevacann',
  formName:'TevaCann - contact form'
};

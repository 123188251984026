import "./formStyle.scss";
import { FormConig } from "../../interfaces";
import { FormEvent, useRef, useState } from "react";
import { Captcha, Condition, Input } from "../index";
import { TextArea } from "../textArea/textarea";
import axios from "axios";
import submitSuccessfully from "../../assets/images/submit-successfully.svg";
import { sendEventGoogleAnalytics } from "../../utils";
import { Dropdown } from "../dropdown/dropdown";

type FormProps = {
  formHeaderText: string;
  subHeader?: string;
  emailTitle: string;
  emailText?: string;
  inputAttributes: FormConig[] | [];
  FormName: string;
  contactNurseConfirm?: FormConig;
  onFormChange: (e: any, attribute: FormConig) => void | undefined;
};

export const Form = ({
  formHeaderText,
  subHeader,
  emailTitle,
  emailText = "",
  inputAttributes,
  contactNurseConfirm,
  FormName,
  onFormChange,
}: FormProps) => {
  const [confirmConditions, setConfirmConditions] = useState(false);
  const [confirmCaptcha, setConfirmCaptcha] = useState(false);
  const [conditionsError, setConditionsError] = useState("");
  const [validForm, setValidForm] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const theForm = useRef<HTMLFormElement>(null);
  const apiUrl = process.env.REACT_APP_API_GATEWAY;

  const checkFormValidation = async (validFields : boolean, form: FormEvent<HTMLFormElement>) =>{
    if (validFields && confirmCaptcha && confirmConditions) {
      sendEventGoogleAnalytics(FormName, 'click','שליחת פרטים')    

      let formData: any = {};
      inputAttributes.map((input) => {
        if (input.name === "statment") {
          const date = new Date();
          const day = date.getDate();
          const month = date.getMonth() + 1;
          const year = date.getFullYear();
          const currentDate = `${day}-${month}-${year}`;
          return (formData[input.label] = currentDate);
        } else {
          return (formData[input.label] =
            form.currentTarget[input.name]?.value);
        }
      });
      setErrorMsg("");
      try {
        setIsLoader(true);
        const response = await axios.post(apiUrl!, {
          emailParams: "/tevacann/email",
          title: emailTitle,
          content: formData,
          additionalText: emailText,
        });
        if (!response.data.errorMessage) {
          setValidForm(true);
        } else {
          console.log("error", response.data.errorMessage);
          setErrorMsg("שליחת הטופס נכשלה.");
          setValidForm(false);
        }
        setIsLoader(false);
      } catch (error) {
        setIsLoader(false);
        setErrorMsg("שליחת הטופס נכשלה.");
        setValidForm(false);
      }
    } else {
      if (!confirmCaptcha) {
        setConditionsError("אנא אשר/י את תנאי השימוש ");
      }
      setValidForm(false);
      setErrorMsg("אנא וודא/י שכל שדות הטופס תקינים ומלאים");
    }
  }

  const sendEmail = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    let validFields = true;
    inputAttributes.forEach((element) => {
      if (element.errorMsg !== "") {
        validFields = false;
      }
    });
    checkFormValidation(validFields, e)
  };

  return (
    <div className="form-container">
      {!validForm ? (
        <div className="form-div">
          <div className="slices">
            <ul className="slices-line"></ul>
          </div>
          <div className="form-header">{formHeaderText}
             {subHeader && <p>{subHeader}</p>}</div>

          <form onSubmit={(e) => sendEmail(e)} ref={theForm}>
            {inputAttributes.map((attribute: FormConig) => {
              return (
                <div key={attribute.id}>
                  {attribute.domType === "input" && (
                    <Input
                      inputAttribute={attribute}
                      onChange={(e) => onFormChange(e, attribute)}
                    />
                  )}

                  {attribute.domType === "textarea" && (
                    <TextArea
                      inputAttribute={attribute}
                      onChange={(e) => onFormChange(e, attribute)}
                    />
                  )}
                  {attribute.domType === "dropdown" && (
                    <Dropdown
                      inputAttribute={attribute}
                      onChange={(e) => onFormChange(e, attribute)}
                    />
                  )}
                </div>
              );
            })}
            <div className="conditions">
              <Condition
                setConfirmConditions={setConfirmConditions}
                FormName = {FormName}
                contactNurseConfirm={contactNurseConfirm}
              />
              {<Captcha setConfirmCaptcha={setConfirmCaptcha} FormName = {FormName} />}
              {!(confirmCaptcha && confirmConditions) && (
                <span className="invalid-feedback">{conditionsError}</span>
              )}
            </div>
            <div className="submit">
              {isLoader ? (
              <div className="loader"></div>
              ) : (
                <button className="submit-button" type="submit">
                  שליחת פרטים
                </button>
              )}
              <span className="invalid-feedback">{errorMsg}</span>
            </div>
          </form>
        </div>
      ) : (
        <div className="form-submitted-successfully">
          <img
            alt="submit-successfully"
            className="submit-successfully"
            src={submitSuccessfully}
          />
          <h1>הבקשה נשלחה בהצלחה!</h1>
        </div>
      )}
    </div>
  );
};
